import styled from "styled-components"

import aboutBgImage from "assets/images/about-bg.png"

const AboutSectionWrapper = styled.div `
  padding: 85px 0;

  

  // background: url(${aboutBgImage});
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  .omnize-about-line{
    border-top: 1px solid #00ACF1;
    width: 60%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 42px;
  }
  .col-border{
    border: 1px solid #00ACF1;
  }

.omnize-back-row{
    background: #160d3f85;
    border-radius: 12px;
    opacity: 0.8;

.omnize-div{
  margin: 51px 227px 55px 227px;
  }
}
  .new-row{
    margin-top: 82px;
  }
  
.omnize-Texts{
  text-align: center;
  font: normal normal normal 17px/26px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif !important;
}
  .section__particleEclipse{
    position: absolute;
    right: -100px;
    bottom: 550px;
  }
  .omnize-divs {
    text-align: center;
    padding: 10px 0px 74px;
}

.section__particleIcon{
  margin-top:38px;
}

.omnize-about-header{
  text-align: center;
font: normal normal 600 21px/65px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
margin-bottom: 7px;
font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif !important;
}
.omnize-bottom{
  margin-top: 44px;
}
  .mb-4{
    margin-bottom: 1.5rem;
  }
  .mb-none{
    margin-bottom:0px;
  }

  .mr-2{
    margin-right:10px;
  }

  .ml-2{
    margin-left:10px;
  }

  .section__particleSquare{
    z-index: -1;
    position: absolute;
    left: 133px;
    top: 58px;
    opacity: 1 !important;
  }
.omnize-Text{
  text-align: center;
  font: italic normal 300 17px/26px Open Sans;
letter-spacing: 0px;
color: #ffffff;
opacity: 1;
font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif !important;
}
.omnize-about{
  font: normal normal 600 37px/65px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif !important;

}
  .about-list {
    margin-top: 24px;
    span {
      font-size: 18px;
      display: block;
      position: relative;
      padding-left: 43px;
      color: #fff;
      margin-bottom: 20px;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 30px;
        width: 30px;
        background: transparent;
        border: 8px solid #c838e7;
        border-radius: 50%;
        content: " ";
      }

      &:nth-child(2) {
        &::before {
          border-color: #6df7fb;
        }
      }
      &:nth-child(3) {
        &::before {
          border-color: #4268db;
        }
      }
    }
  }

  .about-btn-wrapper {
    a + a {
      margin-left: 20px;
    }

    a {
      display: inline-flex;
      border-color: #fff;
      justify-content: center;
      align-items: center;
      margin-top: 53px;
      transition: all 0.3s ease-in;
      &:hover {
      }
      svg {
        margin-right: 10px;
        font-size: 23px;
      }

      &:hover,
      &.btn-fill {
        border: none;
        background: rgb(37, 42, 213);
        background: -moz-linear-gradient(
          93deg,
          rgba(37, 42, 213, 1) 0%,
          rgba(122, 49, 222, 1) 52%,
          rgba(196, 56, 231, 1) 100%
        );
        background: -webkit-linear-gradient(
          93deg,
          rgba(37, 42, 213, 1) 0%,
          rgba(122, 49, 222, 1) 52%,
          rgba(196, 56, 231, 1) 100%
        );
        background: linear-gradient(
          93deg,
          rgba(37, 42, 213, 1) 0%,
          rgba(122, 49, 222, 1) 52%,
          rgba(196, 56, 231, 1) 100%
        );
      }
    }
  }

  @media only screen and (max-width: 912px) {
    .about-image {
      margin-top: 70px;
    }
    .title__wrapper {
      .heading {
        font-size: 30px;
      }
    }
    .about-list {
      span {
        font-size: 17px;
        &::before {
          top: 3px;
          width: 25px;
          height: 25px;
        }
      }
    }

    .about-btn-wrapper {
      a {
        width: 155px;
        font-size: 14px;
        margin-top: 40px;
      }
    }
  }

  @media (min-width: 992px){
 .new-border {
      max-width: 49% !important;
  }
}


  @media only screen and (max-width: 768px) {
    .about-image {
      width: 400px;
      margin: 0 auto 50px auto;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 375px) {
    .title__wrapper .heading {
      font-size: 25px;
      line-height: 35px;
    }
    .about-btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        &:last-child {
          margin: 20px 0 0;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .omnize-back-row .omnize-div{
      margin: 0px;
      padding: 20px;
    }
    .section__particleEclipse {
      right: -0px;
      bottom: 855px;
      width: 30%;
  }

  .mr-2 {
    margin: 0px 10px;
}

.ml-2 {
  
  margin: 0px 10px;
  margin-top:10px;
}
.omnize-about-header{
  line-height: 30px;
}
    }
`

export default AboutSectionWrapper