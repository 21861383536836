import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"
// Webpack

// CommonJS modules
import sndservImage from "assets/images/omnize-images/Order management.png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <div
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="700"
            className="sndbanner"
          >
            <img
              className="banner__thumb"
              alt="cryptik banner thumb"
              src={sndservImage}
            />{" "}
          </div>
          <Row>
            <Col
              className="lg-8 md-10 xs-12 "
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="700"
            >
              <Box className="banner-content omni-banner-content">
                <Heading> Order management systems </Heading>{" "}
                <Text className="omnize-para-text omnize-para-text-two">
                  Providing your clients with a seamless customer journey is
                  mandatory in today’ s business dynamics.Omnize ensures
                  efficient order processing workflows to increase productivity
                  across all channels, integrating automation whenever possible.{" "}
                </Text>{" "}
                <Text className="omnize-para-text">
                  We use high - performance, scalable and purpose - built
                  connectors for the most relevant e - commerce platforms and
                  marketplaces to help our clients manage all their sales in one
                  place.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    Learn More{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
