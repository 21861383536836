import React from "react"
import { Link } from "gatsby"
import { FaGooglePlay, FaApple } from "react-icons/fa"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
import OmnizeaboutSqur from "assets/images/omnize-images/Group 22.svg"
import OmnizeaboutEclipse from "assets/images/omnize-images/Ellipse 5.svg"
import OmnizeaboutIconLeft from "assets/images/omnize-images/Group 1291.svg"
import OmnizeaboutIconRight from "assets/images/omnize-images/Group 1292.svg"

import aboutImage from "assets/images/about/about.svg"
import AboutSectionWrapper from "./about.style"

const About = () => {
  return (
    <AboutSectionWrapper id="about">
      <Container>
        <div className="omnize-about-line"> </div>{" "}
        <Text className="omnize-about"> Why choose Omnize </Text>{" "}
        <img
          src={OmnizeaboutSqur}
          className="section__particleSquare"
          alt="cryptik particles"
        />
        <Row
          className="omnize-back-row"
          data-sal="zoom-in"
          data-sal-easing="ease"
          data-sal-duration="700"
        >
          <div className="omnize-div">
            <Text className="omnize-Text mb-4">
              Omnize is an efficient and intuitive all - in -one E - Commerce
              solution that provides you with the tools you need to leverage all
              your business processes in one centralized platform.From fully -
              autonomous order and fulfillment flows, real - time operations
              reporting on all aspects of your business to a highly intuitive
              marketplace integration, Omnize is a reliable asset to global{" "}
            </Text>{" "}
            <Text className="omnize-Text mb-none">
              brands, wholesalers, and retailers.{" "}
            </Text>{" "}
            <Text className="omnize-Text">
              Using Omnize, brands, retailers, and wholesalers obtain the
              highest return on investment possible and diminish risks
              associated with human error or manual data management.Own,
              control, and manage all your business assets in one place, letting
              technology do the work for you.{" "}
            </Text>{" "}
          </div>{" "}
        </Row>{" "}
        <img
          src={OmnizeaboutEclipse}
          className="section__particleEclipse"
          alt="cryptik particles"
        />
        <Row className="new-row">
          <Col
            className="omnize-back-row col-border new-border lg-6 md-6 sm-12 mr-2"
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <div className="omnize-divs">
              <div className="omnize-about-icon">
                <img
                  src={OmnizeaboutIconLeft}
                  className="section__particleIcon"
                  alt="cryptik particles"
                />
              </div>{" "}
              <Text className="omnize-about-header">
                Improve customer experience{" "}
              </Text>{" "}
              <Text className="omnize-Texts omnize-box mb-4 ">
                Improve order fulfillment, inventory planning, multichannel
                sales, operations, payments management in a hassle - free
                manner.Get more time to focus on smart marketing strategies
                while automating tedious processes whenever possible in a single
                platform for all your needs.{" "}
              </Text>{" "}
            </div>{" "}
          </Col>{" "}
          <Col
            className="omnize-back-row col-border new-border lg-6 md-6 sm-12 ml-2"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <div className="omnize-divs">
              <div className="omnize-about-icon">
                <img
                  src={OmnizeaboutIconRight}
                  className="section__particleIcon omnize-bottom"
                  alt="cryptik particles"
                />
              </div>{" "}
              <Text className="omnize-about-header">
                Make intelligent decisions based on real insights{" "}
              </Text>{" "}
              <Text className="omnize-Texts omnize-box">
                Gain 24 / 7 access to all your business data in one
                place.Identify issues, opportunities, and make better decisions
                in real - time by consulting your statistics on a daily basis.{" "}
              </Text>{" "}
            </div>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>{" "}
    </AboutSectionWrapper>
  )
}

export default About
