import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import fstBannerImage from "assets/images/omnize-images/Asset 3@2x.png"
import eclipseBannerImage from "assets/images/omnize-images/Group 12.svg"
import BannerImage from "assets/images/banners/banner-three/cryptik-banner-three-thumb.svg"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="700"
          />{" "}
          <Row className="omni-left-Text">
            <Col className="lg-8 md-10 xs-12">
              <Box
                className="banner-content omni-banner-content"
                data-sal="slide-down"
                data-sal-easing="ease"
                data-sal-duration="700"
              >
                <Text className="omi-banner"> Skyrocket </Text>{" "}
                <Heading> Your Business’ Growth </Heading>{" "}
                <img
                  src={eclipseBannerImage}
                  className="section__particle newSvg"
                  alt="cryptik particles"
                />
                <Text className="omnize-para-text">
                  Omnize is a system designed by experienced merchants for
                  merchants. We are aware of the challenges you face within the
                  highly-competitive and ever-growing commerce environment – so
                  we developed a one-stop solution tailored to the
                  particularities of retail that responds to all your needs. Our
                  software is not reduced to a simple exchange of data but
                  allows you to manage complex workflows and improve your
                  business’s operational aspects.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    Learn More{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
