import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Navigation from "sections/Navigation"
import OminizeBanner from "sections/Ominize-Banner"
import OminizeBannerTwo from "sections/Ominize-Banner-Two"

import OmnizeService from "sections/Omnize-Service"
import OmnizeServiceBannerFst from "sections/Ominize-Service-Banner-Fst"
import OmnizeServiceBannerTwo from "sections/Ominize-Service-Banner-Two"
import OmnizeServiceBannerThird from "sections/Ominize-Service-Banner-Third"
import OmnizeServiceBannerFour from "sections/Ominize-Service-Banner-Four"
import OmnizeServiceBannerFive from "sections/Ominize-Service-Banner-Five"
import OmnizeServiceBannerSix from "sections/Ominize-Service-Banner-Six"
// import BannerTwo from "sections/BannerSlider"
// import Service from "sections/Service"
import CoinFund from "sections/CoinFund"

import About from "sections/About"
import OmnizeAbout from "sections/Why-Omnize"
import OmnizeWhyWeServe from "sections/Omnize-Why-We-Serve"
import OmnizeWhyWeServeFst from "sections/Ominize-Why-serve-Fst"
import OmnizeWhyWeServeSnd from "sections/Ominize-Why-serve-Snd"
import OmnizeWhyWeServeBrand from "sections/Omnize-Why-serve-Brands"

import Awards from "sections/Awards"
import UserMap from "sections/UserMap"
import Wallet from "sections/Wallet"
import Statistics from "sections/Statistics"
import Stack from "sections/Stack"
import Faq from "sections/Faq"
import Footer from "sections/Footer"
import OmnizeFooter from "sections/Omnize-Footer"

import theme from "assets/theme/theme"
import GlobalStyle from "assets/theme"
import fontdesign from "./index_font.style"

const IndexPage = () => (
  // Defining WOW

  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <div className="sidebar-design">
        <SEO title="Home" />
        <Navigation />
        <OminizeBanner />
        <OminizeBannerTwo />
        <OmnizeService />
        <OmnizeServiceBannerFst />

        <OmnizeServiceBannerTwo />
        <OmnizeServiceBannerThird />
        <OmnizeServiceBannerFour />
        <OmnizeServiceBannerFive />
        <OmnizeServiceBannerSix />

        <OmnizeAbout />
        <OmnizeWhyWeServe />
        <OmnizeWhyWeServeFst />
        <OmnizeWhyWeServeSnd />
        <OmnizeWhyWeServeBrand />

        <OmnizeFooter />
      </div>{" "}
    </Layout>{" "}
  </ThemeProvider>
)

export default IndexPage
