import styled from "styled-components"

const BlogSideBarWrapper = styled.div `
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif !important;

  .sidebar-design {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }
`

export default BlogSideBarWrapper