import styled from "styled-components"
import footerBg from "assets/images/footer-bg.png"

const FooterWrapper = styled.div `
  .footer-content-wrapper {
    background: url(${footerBg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .icon-ul {
    display: flex;
    justify-content: center;
    .icon-li {
      width: 35px;
      margin: 12px 5px 0px 5px;
      img {
        width: 35px;
      }
    }
  }

  .header-2 {
    margin-top: 30px;
  }
  .line-side {
    border-right: 1px solid #00acf1;
  }

  @media (min-width: 992px) {
    .footer-section {
      flex: 0 0 26%;
      max-width: 26%;
    }

    .footer-section-two {
      flex: 0 0 24%;
      max-width: 24%;
    }
  }

  .Foloow-US {
    margin-top: 15px;
    text-align: center;
    font: normal normal 600 14px/14px Poppins;
    letter-spacing: 0px;
    color: #00acf1;
    opacity: 1;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }

  .service-link {
    text-align: left;
    font: normal normal 300 16px/34px Poppins !important;
    letter-spacing: 0px;
    color: #aeb2c9;
    opacity: 1;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif !important;
  }
  a {
    &:hover {
      color: #fff;
    }
  }

  .footer-widgets {
    margin-bottom: 60px;

    a {
      color: #ffffff;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
      padding-bottom: 5px;

      &:hover {
        transition: border-bottom 0.3s linear;
        border-bottom: 1px solid #fff;
      }
    }

    img {
      width: 180px;
      // margin-bottom: 27px;
    }

    &.company-desc {
      .text {
        line-height: 26px;
      }
    }

    &.address {
      font-size: 18px;
      p {
        line-height: 38px;
      }
    }

    .heading {
      // font-size: 24px;
      // font-weight: 500;
      // color: #beabdf;
      // margin-bottom: 23px;

      text-align: left;
      font: normal normal 600 14px/26px Poppins;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 1;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light",
        "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      a {
        display: flex;
        align-items: center;
        svg {
          margin-right: 15px;
        }
      }
    }
  }

  .footer-social-links {
    padding: 45px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid #4e55aa;
    border-bottom: 0.5px solid #4e55aa;
    a {
      font-size: 36px;
      font-weight: 700;
      color: #beabdf;
      &:hover {
        color: #fff;
      }
    }
  }

  .copyright-text {
    display: flex;
    justify-content: space-between;
    padding: 65px 0 50px 0px;
  }
  @media only screen and (max-width: 480px) {
    .footer-social-links {
      flex-wrap: wrap;
      a {
        max-width: 16%;
        flex: 0 0 16%;
        margin: 15px 0;
      }
    }
    .copyright-text {
      flex-direction: column-reverse;
    }
  }
`

export default FooterWrapper