import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import fstBannerImage from "assets/images/omnize-images/Inventory.png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="700"
          />{" "}
          <Row
            className="omni-left-Text"
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <Col className="lg-8 md-10 xs-12">
              <Box className="banner-content omni-banner-content">
                <Heading> Inventory management systems </Heading>{" "}
                <Text className="omnize-para-text omnize-para-text-two">
                  Omnize’ s Inventory Management systems allow you to manage
                  inventory, orders, warehouses, accounting, fulfillment,
                  shipping, purchases, suppliers, POS and CRM all in one smooth
                  and centralized system, taking potential manual errors, sync
                  delays, or loss of data out of the equation.{" "}
                </Text>{" "}
                <Text className="omnize-para-text">
                  With our help, you will gain full control of your inventory,
                  allowing you to plan it in an intelligent and cost - efficient
                  manner.Keep track of your revenue, profit, turnover, gross
                  margin, identify overstocked SKUS, apply discounts, and detect
                  trends to adjust your strategy accordingly.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    Learn More{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
