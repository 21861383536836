import styled from "styled-components"
import OmnizeService from "assets/images/omnize-images/Group 1295.svg"

const ServiceSectionWrapper = styled.div `
  padding: 100px 0 50px 0;

  .omnizeService {
    background: url(${OmnizeService});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding: 70px 0px;
    .omnizeHeadText {
      text-align: center;
      font: normal normal bold 32px/65px Poppins;
      letter-spacing: 0px;
      color: #00acf1;
      opacity: 1;
      padding-top: 80px;
      // padding-top: 115px;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light",
        "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
    }
  }
  @media (max-width: 600px) {
    .omnizeService {
      background-size: 195% 156%;
      padding: 10px 0px;
      .omnizeHeadText {
        padding-top: 50px;
      }
    }
  }
  .service-item-wrapper {
    padding: 25px 32px;
    border-radius: 10px;
    background-color: #2b1867;

    .service-item {
      padding: 15px 0;
      border-radius: 8px;
      transition: all 0.3s ease-in;
      &:hover {
        background: #280d57;
      }
      img {
        height: 80px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 14px 0;
      }
    }

    p {
      margin: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .service-item {
      margin-bottom: 30px;
    }
    .service-item-wrapper {
      padding: 45px 32px 25px 32px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 55px 0 50px 0;
  }
`

export default ServiceSectionWrapper