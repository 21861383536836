import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import fstBannerImage from "assets/images/omnize-images/Operational analytics (2).png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="700"
          />{" "}
          <Row
            className="omni-left-Text"
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="700"
          >
            <Col className="lg-8 md-10 xs-12">
              <Box className="banner-content omni-banner-content">
                <Heading> Operational analytics </Heading>{" "}
                <Text className="omnize-para-text omnize-para-text-two">
                  Our advanced operational analytics solutions empower you to
                  run data - driven business operations.{" "}
                </Text>{" "}
                <Text className="omnize-para-text">
                  You can improve the resource allocation, fast - track the
                  reporting processes, reduce downtime, and even improve the
                  interdepartmental collaboration by continually running
                  workflow analysis supported by comprehensive reports and
                  dashboards kept in one place.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    LEARN MORE{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
