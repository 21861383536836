import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import fstBannerImage from "assets/images/omnize-images/Asset 2.png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="700"
          />{" "}
          <Row>
            <Col
              className="lg-8 md-10 xs-12"
              data-sal="slide-right"
              data-sal-easing="ease"
              data-sal-duration="700"
            >
              <Box className="banner-content omni-banner-content">
                <Heading> Payments </Heading>{" "}
                <Text className="omnize-para-text omnize-para-text-two">
                  Our platform supports your payment workflows by allowing you
                  to integrate your own payment accounts, process transactions
                  securely, and pay invoices with just one click.{" "}
                </Text>{" "}
                <Text className="omnize-para-text">
                  For each integrated gateway, you can give one or more
                  employees authority to take payment for orders, settle
                  accounts, and process refunds, keeping full control over your
                  business’ finances.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    LEARN MORE{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
