import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"
import fstBannerImage from "assets/images/omnize-images/cube.png"
import eclipseBannerImage from "assets/images/omnize-images/Ellipse 1.svg"
import particleTopLeft from "assets/images/particles/banner/particle-top-left.png"
import particleUnderLogo from "assets/images/particles/banner/particle-under-logo.png"
import prticleTopRight from "assets/images/particles/banner/prticle-top-right.png"
import particleBottomLeft from "assets/images/particles/banner/particle-bottom-left.png"
import particleBottomRight from "assets/images/particles/banner/particle-bottom-right.png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper id="home">
      <Box className="banner-three-wrap">
        <img
          src={particleTopLeft}
          className="section__particle top-left"
          alt="cryptik particles"
        />
        <img
          src={particleUnderLogo}
          className="section__particle two"
          alt="cryptik particles"
        />
        <img
          src={prticleTopRight}
          className="section__particle top-right"
          alt="cryptik particles"
        />
        <img
          src={particleBottomLeft}
          className="section__particle bottom-left "
          alt="cryptik particles"
        />
        <img
          src={particleBottomRight}
          className="section__particle bottom-right"
          alt="cryptik particles"
        />
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-left"
            data-sal-duration="700"
          />{" "}
          <Row>
            <Col className="lg-8 md-10 xs-12">
              <Box
                className="banner-content omni-banner-content"
                data-sal="slide-up"
                data-sal-duration="700"
              >
                <img
                  src={eclipseBannerImage}
                  className="section__particle newSvg"
                  alt="cryptik particles"
                />
                <Text className="omi-banner"> WE PLACE OURSELVES </Text>{" "}
                <Heading> At The Core Of The Commerce Ecosystem </Heading>{" "}
                <Text className="omnize-para-text">
                  Omnize is a system designed by experienced merchants for
                  merchants.We are aware of the challenges you face within the
                  highly - competitive and ever - growing commerce environment–
                  so we developed a one - stop solution tailored to the
                  particularities of retail that responds to all your needs.Our
                  software is not reduced to a simple exchange of data but
                  allows you to manage complex workflows and improve your
                  business’ s operational aspects.{" "}
                </Text>{" "}
                <Box className="banner-btn btnnew">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    Learn More{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
