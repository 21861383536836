import React from "react"

import Text from "reusecore/Text"

import OmnizeService from "assets/images/omnize-images/Group 1295.svg"

import data from "assets/data/service"
import ServiceSectionWrapper from "./service.style"

const Service = () => {
  return (
    <ServiceSectionWrapper id="service">
      <div className="omnizeService">
        <Text className="omnizeHeadText"> Who We Serve </Text>{" "}
      </div>{" "}
    </ServiceSectionWrapper>
  )
}

export default Service
