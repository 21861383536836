import React from "react"
import { Link } from "gatsby"
import { FaGooglePlay, FaApple } from "react-icons/fa"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import OmnizeaboutSqur from "assets/images/omnize-images/Ellipse 646.svg"
import OmnizeaboutEclipse from "assets/images/omnize-images/Ellipse 5.svg"
import OmnizeaboutLine from "assets/images/omnize-images/Lines.svg"

import aboutImage from "assets/images/about/about.svg"
import AboutSectionWrapper from "./about.style"

const About = () => {
  return (
    <AboutSectionWrapper id="about">
      <Container>
        <Text className="omnize-about">Brands</Text>
        <img
          src={OmnizeaboutSqur}
          className="section__particleSquare"
          alt="cryptik particles"
        />
        <Row className="omnize-back-row">
          <div className="omnize-div">
            <Text className="omnize-Text mb-4">
              Omnize works with established brands and startups alike, providing
              entrepreneurs with the tools they need to streamline their
              operations in one place. Whether you’re selling
              directly-to-consumer or in bulk, our system eases the process of
              selling across multiple channels while keeping all the operations
              in order.{" "}
            </Text>{" "}
            <Text className="omnize-Text mb-none">
              Omnize automatically syncs all available inventory across all your
              channels, speeds up delivery, and ensures smooth returns (when
              needed),{" "}
            </Text>{" "}
            <Text className="omnize-Text">
              ultimately helping you retain customers.
            </Text>{" "}
            <img
              src={OmnizeaboutLine}
              className="section__particleLine"
              alt="cryptik particles"
            />
          </div>{" "}
        </Row>{" "}
        <img
          src={OmnizeaboutEclipse}
          className="section__particleEclipse"
          alt="cryptik particles"
        />
      </Container>{" "}
    </AboutSectionWrapper>
  )
}

export default About
