import React from "react"
import { Link } from "gatsby"

import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import Subscribe from "sections/Subscribe"
import OmnizeSubscribe from "sections/Omnize-Subscribe"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { List, ListItem } from "reusecore/List"

import facebook from "assets/images/omnize-images/Group 1657.svg"
import twitter from "assets/images/omnize-images/Group 1658.svg"
import linkedIn from "assets/images/omnize-images/Group 1659.svg"
import youtube from "assets/images/omnize-images/Group 1660.svg"

import cryptikFooterLogo from "assets/images/logo-white.png"
import data from "assets/data/footer"
import FooterWrapper from "./footer.style"

const Footer = () => {
  return (
    <FooterWrapper>
      <Box className="footer-content-wrapper">
        <OmnizeSubscribe />
        <Container>
          <Row>
            <Col className="lg-3 sm-6 footer-section">
              <Box className="footer-widgets company-desc">
                <Heading as="h2"> OUR SERVICES </Heading>{" "}
                <List>
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Order Management Systems{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Inventory Management Systems{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Shipping Aggregation{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Supply chain management{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Payments{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Operational analytics{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                </List>{" "}
              </Box>{" "}
            </Col>{" "}
            <Col className="lg-3 sm-6 footer-section-two">
              <Box className="footer-widgets">
                <Heading as="h2"> About Us </Heading>{" "}
                <List>
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Why choose Omnize{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="/blog-grid" className="service-link">
                      {" "}
                      Blogs & Articles{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                </List>{" "}
                <Heading as="h2" className="header-2">
                  {" "}
                  Help{" "}
                </Heading>{" "}
                <List>
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Call Us{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Email Us{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                </List>{" "}
              </Box>{" "}
            </Col>{" "}
            <Col className="lg-3 sm-6 line-side">
              <Box className="footer-widgets">
                <Heading as="h2"> Other </Heading>{" "}
                <List>
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Terms & Conditions{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Privacy Policy{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                  <ListItem>
                    <Link to="#" className="service-link">
                      {" "}
                      Cookies Policy{" "}
                    </Link>{" "}
                  </ListItem>{" "}
                </List>{" "}
              </Box>{" "}
            </Col>{" "}
            <Col className="lg-3 sm-6 ">
              <Box className="footer-widgets address">
                <Text as="h2" className="Foloow-US">
                  {" "}
                  FOLLOW US ON{" "}
                  <List className="icon-ul">
                    <ListItem className="icon-li">
                      <Link to="#" className="service-link">
                        <img
                          className="banner__thumb_icon"
                          alt="cryptik banner thumb"
                          src={facebook}
                        />{" "}
                      </Link>{" "}
                    </ListItem>
                    <ListItem className="icon-li">
                      <Link to="#" className="service-link">
                        <img
                          className="banner__thumb_icon"
                          alt="cryptik banner thumb"
                          src={twitter}
                        />{" "}
                      </Link>{" "}
                    </ListItem>
                    <ListItem className="icon-li">
                      <Link to="#" className="service-link">
                        <img
                          className="banner__thumb_icon"
                          alt="cryptik banner thumb"
                          src={linkedIn}
                        />{" "}
                      </Link>{" "}
                    </ListItem>
                    <ListItem className="icon-li">
                      <Link to="#" className="service-link">
                        <img
                          className="banner__thumb_icon"
                          alt="cryptik banner thumb"
                          src={youtube}
                        />{" "}
                      </Link>{" "}
                    </ListItem>{" "}
                  </List>{" "}
                </Text>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row>
            <Col className="xs-12">
              <Box className="copyright-text">
                <Text> ©2020 OMNIZE | All Rights Reserved </Text>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </FooterWrapper>
  )
}

export default Footer
