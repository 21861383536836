import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import fstBannerImage from "assets/images/omnize-images/Shipping.png"

import BannerWrapper from "./banner.style"

const BannerThree = () => {
  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={fstBannerImage}
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="700"
          />{" "}
          <Row>
            <Col
              className="lg-8 md-10 xs-12"
              data-sal="slide-down"
              data-sal-easing="ease"
              data-sal-duration="700"
            >
              <Box className="banner-content omni-banner-content">
                <Heading> Shipping aggregation </Heading>{" "}
                <Text className="omnize-para-text omnize-para-text-two">
                  Our shipping aggregation solution helps you process your
                  orders faster and increase customer satisfaction.Customize the
                  system to align perfectly with your processes and simplify the
                  way you receive and ship your orders.{" "}
                </Text>{" "}
                <Text className="omnize-para-text">
                  Moreover, Omnize allows you to trigger actions automatically
                  and define channel - specific templates for delivery.Integrate
                  various shipping methods and choose if the costs should be
                  split according to the products’ weight, quantity, or price.{" "}
                </Text>{" "}
                <Box className="banner-btn">
                  <Link to="#" className="btn btn-fill">
                    {" "}
                    LEARN MORE{" "}
                  </Link>{" "}
                </Box>{" "}
              </Box>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Box>{" "}
    </BannerWrapper>
  )
}

export default BannerThree
